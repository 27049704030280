import { FC, MouseEventHandler, ReactNode } from 'react';

import classNames from 'classnames';

import ImageProxy from '@next-image-proxy';

import { MEMBERSHIP_TIER_ID, MembershipModel, MembershipTierId } from '@api';

import NextLink from '@shared/common/components/NextLink';
import { getProcessEnvGlobalConfig } from '@shared/config/global';
import formatUsername from '@shared/helpers/formatUsername';
import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

export type AvatarSize = 'sm' | 'md' | 'lg';

const avatarSizeMap: Record<AvatarSize, number> = {
  sm: 32,
  md: 50,
  lg: 70,
};

export const membershipBgClassName: Record<MembershipTierId, string> = {
  [MEMBERSHIP_TIER_ID.FREE]: 'bg-membership-tier--free',
  [MEMBERSHIP_TIER_ID.PLUS]: 'bg-membership-tier--plus',
  [MEMBERSHIP_TIER_ID.PREMIUM]: 'bg-membership-tier--premium',
  [MEMBERSHIP_TIER_ID.PREMIUM_PLUS]: 'bg-membership-tier--premium_plus',
};

const getTierClassName = (sizeInPX: number, tier?: MembershipTierId) => {
  if (!tier) {
    return '';
  }

  const baseClassName = tier === 'FREE' ? 'bg-neutral-200' : membershipBgClassName[tier];

  if (sizeInPX < avatarSizeMap.sm) {
    return `${baseClassName} p-[2px]`;
  }

  if (sizeInPX < avatarSizeMap.md) {
    return `${baseClassName} p-[3px]`;
  }

  if (sizeInPX < 100) {
    return `${baseClassName} p-[4px]`;
  }

  if (sizeInPX < 150) {
    return `${baseClassName} p-[6px]`;
  }

  return `${baseClassName} p-2`;
};

export interface AvatarProps {
  'data-qa'?: string;
  src?: string;
  children?: ReactNode;
  className?: string;
  containerClassName?: string;
  isLink?: boolean;
  openInNewTab?: boolean;
  size?: AvatarSize;
  sizeInPX?: number;
  quality?: number;
  userInfo?: {
    magicId?: string;
    displayName?: string; // 'displayName' and 'name' is the same field just from different API versions
    name?: string;
    membership?: MembershipModel;
  };
  onClick?: MouseEventHandler<HTMLElement>;
}

const AvatarImage = ({
  sizeInPX,
  className,
  userInfo = {},
  ...props
}: Pick<AvatarProps, 'src' | 'className' | 'data-qa' | 'userInfo' | 'onClick' | 'quality'> &
  Required<Pick<AvatarProps, 'sizeInPX'>>) => {
  const { membership } = useFeatureToggles();
  const tier = userInfo?.membership?.tier;
  const tierClassName = membership ? getTierClassName(sizeInPX, tier) : '';

  return (
    <ImageProxy
      alt={`${formatUsername(userInfo)} avatar`}
      width={sizeInPX}
      height={sizeInPX}
      className={classNames(
        'rounded-full border-solid border-transparent flex-shrink-0 aspect-square',
        tierClassName,
        className,
      )}
      {...props}
    />
  );
};

const Avatar: FC<AvatarProps> = ({
  children,
  className,
  containerClassName,
  isLink: isLinkFromProps = true,
  openInNewTab,
  size = 'sm',
  sizeInPX,
  userInfo,
  onClick,
  ...props
}) => {
  const isLink = isLinkFromProps && !!userInfo?.magicId;
  const profileUrl = `${getProcessEnvGlobalConfig('host')}${getProcessEnvGlobalConfig(
    'basePath',
  )}/user/${userInfo?.magicId}`;
  const avatarSizeInPX = sizeInPX ?? avatarSizeMap[size];
  const avatarProps = {
    ...props,
    userInfo,
    className,
    sizeInPX: avatarSizeInPX,
  };

  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  if (isLink) {
    return (
      <NextLink
        className={containerClassName}
        href={profileUrl}
        target={openInNewTab ? '_blank' : '_self'}
        onClick={handleClick}
      >
        <AvatarImage {...avatarProps} />
        {children}
      </NextLink>
    );
  }

  if (containerClassName || children) {
    return (
      <div className={containerClassName} onClick={handleClick}>
        <AvatarImage {...avatarProps} />
        {children}
      </div>
    );
  }

  return <AvatarImage {...avatarProps} onClick={handleClick} />;
};

export default Avatar;
